import React, { Component } from "react";
import { Nav, Row, Col, Container } from "react-bootstrap";
import MK from "../../img/mk24.jpg";
import MKlogo from "../../img/MK_logo_color.png";
import Ordf from "../../img/1ordf.jpg";
import kassor from "../../img/2vice.jpg";
import Instruktor from "../../img/7instruktor.jpg";
import Redaktor from "../../img/3redaktor.jpg";
import PR from "../../img/4pr.jpg";
import Arrangor from "../../img/5arrangor.jpg";
import Organisator from "../../img/6organisator.jpg";
import Mail from "../../img/mail.png";
import Phone from "../../img/phone.png";
// import Blank from "../../img/blank.png";
import { titel, text1, text2, divider, linkStyle } from "../../style.js";

class About extends Component {
  render() {
    let width = window.innerWidth;

    let ordfNamn = "Oliver Kryger ";
    let kassorNamn = "Emil Lindén";
    let instNamn = "Gustav Hartwig";
    let redNamn = "Saga Berggren";
    let prNamn = "Nina Eklund";
    let arrNamn = "Herman Sterner";
    let orgNamn = "Wilma Widegren";

    let ordfTel = "076-030 60 63";
    let kassorTel = "072-215 89 47";
    let instTel = "070-273 65 62";
    let redTel = "072-328 28 61";
    let prTel = "070-823 92 99";
    let arrTel = "076-899 50 84";
    let orgTel = "076-335 03 02";

    if (width < 600) {
      return (
        <Container style={paddingStyle}>
          {/* Row 1 */}
          <Row lg={2} md={1} sm={1} xs={1} style={{ marginTop: "50px" }}>
            <Col>
              <h1 style={titel}>CHALMERS MOTTAGNINGSKOMMITTÉ</h1>
              <div>
                <img
                  src={MKlogo}
                  style={lowOp}
                  opacity={0.1}
                  alt={"MK:s logga"}
                ></img>

                <div style={text2}>
                  För att en mottagning för omkring 3000 nya studenter ska kunna
                  fungera på ett bra sätt finns Mottagningskommittén, MK. Vi är
                  ett organ inom Chalmers Studentkår som samordnar och
                  kontinuerligt utvecklar mottagningen så att de nya
                  chalmeristerna får ett så bra välkomnande som möjligt.
                  <br></br>
                  <br></br>
                  Inåt ansvarar MK för att se till att nödvändig information
                  kommer dit den ska. Högskolan, kåren, olika NollK:n, sektioner
                  och utomstående parter behöver alla både få ut och in
                  information så att de kan genomföra sina delar av
                  mottagningen. Vi planerar och organiserar de centrala
                  arrangemangen och dessutom utvecklar vi spridningen av
                  information till alla nya teknologer. MK består av sju
                  studenter som alla jobbar ideellt utöver studierna med arbetet
                  kring mottagningen. Vi bär mörkblå reflexbyxor med neongula
                  detaljer.
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  Se tidigare MK:{" "}
                  <Nav.Link style={linkStyle} href="/Pateter">
                    Pateter.
                  </Nav.Link>
                  <br></br>
                  <br></br>
                  Osäker på vem du ska kontakta? Skriv till{" "}
                  <a href="mailto:info@mk.chs.chalmers.se" style={linkStyle}>
                    info@mk.chs.chalmers.se
                  </a>
                </div>
              </div>
            </Col>
            <Col>
              <img src={MK} style={imgStyle1} alt={"Gruppbild på MK"}></img>
              {/* <img src={Blank} style={imgStyle1} alt={"Gruppbild på MK"}></img> */}
            </Col>
          </Row>
          <Row style={topMargin}>
            <div style={text1}>MK25</div>
          </Row>
          <Row>
            <div style={divider}></div>
          </Row>
          {/* Ordf */}
          <Row lg={2} md={2} sm={1} xs={1} style={{ marginTop: "20px" }}>
            <Col>
              <img src={Ordf} style={imgStyle} alt={ordfNamn}></img>
              {/* <img src={Blank} style={imgStyle} alt={ordfNamn}></img> */}
            </Col>
            <Col>
              <div style={text1}>Ordförande - {ordfNamn}</div>
              <div style={description}>
                <img style={mailLogo} src={Mail} alt={"Mail"}></img>
                ordf@mk.chs.chalmers.se
              </div>
              <div style={description}>
                <img style={phoneLogo} src={Phone} alt={"Telefon"}></img>
                {ordfTel}
              </div>
              <div style={description}>
                Bild ej uppdaterad. Som ordförande i MK är det jag som överblickar helheten och jag
                jobbar mycket med olika utvecklingsfrågor för att hela tiden
                förbättra Chalmers Studentkårs mottagning. Det är ordförande som
                sköter all kontakt med media vid frågor om mottagningen och jag
                har även nära kontakt med högskolan, NollK och andra kommittéer
                inom kåren i mitt arbete kring mottagningen.
              </div>
            </Col>
          </Row>
          <Row>
            <div style={divider2}></div>
          </Row>

          {/* KASSÖR */}

          <Row lg={2} md={2} sm={1} xs={1} style={{ marginTop: "20px" }}>
            <Col>
              <div style={text1}>Kassör - {kassorNamn}</div>
              <div style={description}>
                <img style={mailLogo} src={Mail} alt={"Mail"}></img>
                kassor@mk.chs.chalmers.se
              </div>
              <div style={description}>
                <img style={phoneLogo} src={Phone} alt={"Telefon"}></img>
                {kassorTel}
              </div>
              <div style={description}>
              Bild ej uppdaterad. Som kassör sköter jag allt som rör MKs ekonomi och budget. Jag ansvarar också för kontakt med företag och sponsorer under mottagningen. Utöver detta planerar jag vår interna teambuilding i samråd med ordförande.
              </div>
            </Col>
            <Col>
              <img src={kassor} style={imgStyleRight} alt={kassorNamn}></img>
              {/* <img src={Blank} style={imgStyleRight} alt={kassorNamn}></img> */}
            </Col>
          </Row>
          <Row>
            <div style={divider2}></div>
          </Row>

          {/* Instruktör */}

          <Row lg={2} md={2} sm={1} xs={1} style={{ marginTop: "50px" }}>
            <Col>
              <img src={Instruktor} style={imgStyle} alt={instNamn}></img>
              {/* <img src={Blank} style={imgStyle} alt={instNamn}></img> */}
            </Col>
            <Col>
              <div>
                <div style={text1}>Instruktör - {instNamn}</div>
                <div style={description}>
                  <img style={mailLogo} src={Mail} alt={"Mail"}></img>
                  instruktor@mk.chs.chalmers.se
                </div>
                <div style={description}>
                  <img style={phoneLogo} src={Phone} alt={"Telefon"}></img>
                  {instTel}
                </div>
                <div style={description}>
                Bild ej uppdaterad. Som instruktör i MK ansvarar jag för planering och genomförande av våra utbildningar. Inför mottagningen hålls flera utbildningar på central nivå för alla NollK, men även för phaddrar och andra arrangerande kommittéer. För att utbildningarna ska bli så bra som möjligt samarbetar vi nära med kårledningen, högskolan, och externa parter. Instruktören har även ansvar för phadderverksamheten vilket innebär möten med phadderchefsansvariga och framtagning av grundmaterial till lokala phadderutbildningar på sektionsnivå. Jag har även hand om och samordnar alla lokalbokningar under mottagningen.
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <div style={divider2}></div>
          </Row>

          {/* Redaktör */}

          <Row lg={2} md={2} sm={1} xs={1} style={{ marginTop: "50px" }}>
            <Col>
              <div>
                <div style={text1}>Redaktör - {redNamn}</div>
                <div style={description}>
                  <img style={mailLogo} src={Mail} alt={"Mail"}></img>
                  redaktor@mk.chs.chalmers.se
                </div>
                <div style={description}>
                  <img style={phoneLogo} src={Phone} alt={"Telefon"}></img>
                  {redTel}
                </div>
                <div style={description}>
                Bild ej uppdaterad. Som redaktör skriver jag MKs alla texter och är sekreterare under möten. Det är jag som läser och granskar alla sektioners moduler innan de går i tryck och som redaktör är jag dessutom ansvarig för maillistor, diskussionsunderlag, MKs dokument och utvecklingen av Chalmers Studentkårs sätt att nå ut till alla nyantagna med information.
                </div>
              </div>
            </Col>
            <Col>
              <img src={Redaktor} style={imgStyleRight} alt={redNamn}></img>
              {/* <img src={Blank} style={imgStyleRight} alt={redNamn}></img> */}
            </Col>
          </Row>
          <Row>
            <div style={divider2}></div>
          </Row>

          {/* PR */}

          <Row lg={2} md={2} sm={1} xs={1} style={{ marginTop: "50px" }}>
            <Col>
              <img src={PR} style={imgStyle} alt={prNamn}></img>
              {/* <img src={Blank} style={imgStyle} alt={prNamn}></img> */}
            </Col>
            <Col>
              <div>
                <div style={text1}>PR - {prNamn}</div>
                <div style={description}>
                  <img style={mailLogo} src={Mail} alt={"Mail"}></img>
                  pr@mk.chs.chalmers.se
                </div>
                <div style={description}>
                  <img style={phoneLogo} src={Phone} alt={"Telefon"}></img>
                  {prTel}
                </div>
                <div style={description}>
                Bild ej uppdaterad. Som PR designar jag alla affischer som MK trycker upp och det är också jag som har huvudansvaret för hemsidan och att MK syns på sociala medier. Som PR läser och kontrollerar jag mottagningens alla nolluppdrag. Utöver det är jag även kalasansvarig och har dessutom fått äran att leda tåget med alla nya chalmerister från Götaplatsen upp till Chalmers första dagen.
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <div style={divider2}></div>
          </Row>

          {/* Arrangör */}

          <Row lg={2} md={2} sm={1} xs={1} style={{ marginTop: "50px" }}>
            <Col>
              <div>
                <div style={text1}>Arrangör - {arrNamn}</div>
                <div style={description}>
                  <img style={mailLogo} src={Mail} alt={"Mail"}></img>
                  arrangor@mk.chs.chalmers.se
                </div>
                <div style={description}>
                  <img style={phoneLogo} src={Phone} alt={"Telefon"}></img>
                  {arrTel}
                </div>
                <div style={description}>
                Bild ej uppdaterad. I MK är det Arrangören som har hand om samlingen på Götaplatsen första dagen. Jag har även huvudansvar för att planera och genomföra Kappseglingen och Rocken som är deltävlingar i Mottagningskampen - Mottagningens stora tävling. 
                </div>
              </div>
            </Col>
            <Col>
              <img src={Arrangor} style={imgStyleRight} alt={arrNamn}></img>
              {/* <img src={Blank} style={imgStyleRight} alt={arrNamn}></img> */}
            </Col>
          </Row>
          <Row>
            <div style={divider2}></div>
          </Row>

          {/* Organisatör */}

          <Row lg={2} md={2} sm={1} xs={1} style={{ marginTop: "50px" }}>
            <Col>
              <img src={Organisator} style={imgStyle} alt={orgNamn}></img>
              {/* <img src={Blank} style={imgStyle} alt={orgNamn}></img> */}
            </Col>
            <Col>
              <div>
                <div style={text1}>Organisatör - {orgNamn}</div>
                <div style={description}>
                  <img style={mailLogo} src={Mail} alt={"Mail"}></img>
                  organisator@mk.chs.chalmers.se
                </div>
                <div style={description}>
                  <img style={phoneLogo} src={Phone} alt={"Telefon"}></img>
                  {orgTel}
                </div>
                <div style={description}>
                Bild ej uppdaterad. Som Organisatör i MK planerar och organiserar jag samlingen på Teknologgården och presentationerna i RunAn första dagen. Utöver det ansvarar jag dessutom för Tivolit. Det är även jag som planerar och styr upp Finalen och Cheeret, de sista deltävlingarna i den prestigefyllda tävlingen Mottagningskampen. Jag hjälper även Arrangören med de övriga centrala arrangemangen.
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      );







    } else {
      return (
        <Container style={paddingStyle}>
          {/* Row 1 */}
          <Row lg={2} md={1} sm={1} xs={1} style={{ marginTop: "50px" }}>
            <Col>
              <h1 style={titel}>CHALMERS MOTTAGNINGSKOMMITTÉ</h1>
              <div>
                <img
                  src={MKlogo}
                  style={lowOp}
                  opacity={0.1}
                  alt={"MK:s logga"}
                ></img>

                <div style={text2}>
                  <br></br>
                  För att en mottagning för omkring 3000 nya studenter ska kunna
                  fungera på ett bra sätt finns Mottagningskommittén, MK. Vi är
                  ett organ inom Chalmers Studentkår som samordnar och
                  kontinuerligt utvecklar mottagningen så att de nya
                  chalmeristerna får ett så bra välkomnande som möjligt.
                  <br></br>
                  <br></br>
                  Inåt ansvarar MK för att se till att nödvändig information
                  kommer dit den ska. Högskolan, kåren, olika NollK:n, sektioner
                  och utomstående parter behöver alla både få ut och in
                  information så att de kan genomföra sina delar av
                  mottagningen. Vi planerar och organiserar de centrala
                  arrangemangen och dessutom utvecklar vi spridningen av
                  information till alla nya teknologer. MK består av sju
                  studenter som alla jobbar ideellt utöver studierna med arbetet
                  kring mottagningen. Vi bär mörkblå reflexbyxor med neongula
                  detaljer.
                  <br></br>
                  <br></br>
                  <br></br>
                  Osäker på vem du ska kontakta? Skriv till{" "}
                  <a href="mailto:info@mk.chs.chalmers.se" style={linkStyle}>
                    info@mk.chs.chalmers.se
                  </a>
                  <br></br>
                  <br></br>
                  Se tidigare MK:{" "}
                  <Nav.Link href="/Pateter" style={linkStyle}>
                    Pateter.
                  </Nav.Link>
                  <br></br>
                </div>
              </div>
            </Col>
            <Col>
              <img src={MK} style={imgStyle1} alt={"Gruppbild på MK"}></img>
              {/* <img src={Blank} style={imgStyle1} alt={"Gruppbild på MK"}></img> */}
            </Col>
          </Row>
          <Row style={topMargin}>
            <div style={text1}>MK25</div>
          </Row>
          <Row>
            <div style={divider}></div>
          </Row>

          {/* Ordf */}
          <Row lg={2} md={2} sm={1} xs={1} style={{ marginTop: "20px" }}>
            <Col>
              <img src={Ordf} style={imgStyle} alt={ordfNamn}></img>
              {/* <img src={Blank} style={imgStyle} alt={ordfNamn}></img> */}
            </Col>
            <Col>
              <div style={text1}>Ordförande - {ordfNamn}</div>
              <div style={description}>
                <img style={mailLogo} src={Mail} alt={"Mail"}></img>
                ordf@mk.chs.chalmers.se
              </div>
              <div style={description}>
                <img style={phoneLogo} src={Phone} alt={"Telefon"}></img>
                {ordfTel}
              </div>
              <div style={description}>
              Bild ej uppdaterad. Som ordförande i MK är det jag som överblickar helheten och jag
                jobbar mycket med olika utvecklingsfrågor för att hela tiden
                förbättra Chalmers Studentkårs mottagning. Det är ordförande som
                sköter all kontakt med media vid frågor om mottagningen och jag
                har även nära kontakt med högskolan, NollK och andra kommittéer
                inom kåren i mitt arbete kring mottagningen.
              </div>
            </Col>
          </Row>
          <Row>
            <div style={divider2}></div>
          </Row>

          {/* KASSÖR */}

          <Row lg={2} md={2} sm={1} xs={1} style={{ marginTop: "20px" }}>
            <Col>
              <div style={text1}>Kassör - {kassorNamn}</div>
              <div style={description}>
                <img style={mailLogo} src={Mail} alt={"Mail"}></img>
                kassor@mk.chs.chalmers.se
              </div>
              <div style={description}>
                <img style={phoneLogo} src={Phone} alt={"Telefon"}></img>
                {kassorTel}
              </div>
              <div style={description}>
              Bild ej uppdaterad. Som kassör sköter jag allt som rör MKs ekonomi och budget. Jag ansvarar också för kontakt med företag och sponsorer under mottagningen. Utöver detta planerar jag vår interna teambuilding i samråd med ordförande.
              </div>
            </Col>
            <Col>
              <img src={kassor} style={imgStyleRight} alt={kassorNamn}></img>
              {/* <img src={Blank} style={imgStyleRight} alt={kassorNamn}></img> */}
            </Col>
          </Row>
          <Row>
            <div style={divider2}></div>
          </Row>

          {/* Instruktör */}

          <Row lg={2} md={2} sm={1} xs={1} style={{ marginTop: "50px" }}>
            <Col>
              <img src={Instruktor} style={imgStyle} alt={instNamn}></img>
              {/* <img src={Blank} style={imgStyle} alt={instNamn}></img> */}
            </Col>
            <Col>
              <div>
                <div style={text1}>Instruktör - {instNamn}</div>
                <div style={description}>
                  <img style={mailLogo} src={Mail} alt={"Mail"}></img>
                  instruktor@mk.chs.chalmers.se
                </div>
                <div style={description}>
                  <img style={phoneLogo} src={Phone} alt={"Telefon"}></img>
                  {instTel}
                </div>
                <div style={description}>
                Bild ej uppdaterad. Som instruktör i MK ansvarar jag för planering och genomförande av våra utbildningar. Inför mottagningen hålls flera utbildningar på central nivå för alla NollK, men även för phaddrar och andra arrangerande kommittéer. För att utbildningarna ska bli så bra som möjligt samarbetar vi nära med kårledningen, högskolan, och externa parter. Instruktören har även ansvar för phadderverksamheten vilket innebär möten med phadderchefsansvariga och framtagning av grundmaterial till lokala phadderutbildningar på sektionsnivå. Jag har även hand om och samordnar alla lokalbokningar under mottagningen.
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <div style={divider2}></div>
          </Row>

          {/* Redaktör */}

          <Row lg={2} md={2} sm={1} xs={1} style={{ marginTop: "50px" }}>
            <Col>
              <div>
                <div style={text1}>Redaktör - {redNamn}</div>
                <div style={description}>
                  <img style={mailLogo} src={Mail} alt={"Mail"}></img>
                  redaktor@mk.chs.chalmers.se
                </div>
                <div style={description}>
                  <img style={phoneLogo} src={Phone} alt={"Telefon"}></img>
                  {redTel}
                </div>
                <div style={description}>
                Bild ej uppdaterad. Som redaktör skriver jag MKs alla texter och är sekreterare under möten. Det är jag som läser och granskar alla sektioners moduler innan de går i tryck och som redaktör är jag dessutom ansvarig för maillistor, diskussionsunderlag, MKs dokument och utvecklingen av Chalmers Studentkårs sätt att nå ut till alla nyantagna med information.
                </div>
              </div>
            </Col>
            <Col>
              <img src={Redaktor} style={imgStyleRight} alt={redNamn}></img>
              {/* <img src={Blank} style={imgStyleRight} alt={redNamn}></img> */}
            </Col>
          </Row>
          <Row>
            <div style={divider2}></div>
          </Row>

          {/* PR */}

          <Row lg={2} md={2} sm={1} xs={1} style={{ marginTop: "50px" }}>
            <Col>
              <img src={PR} style={imgStyle} alt={prNamn}></img>
              {/* <img src={Blank} style={imgStyle} alt={prNamn}></img> */}
            </Col>
            <Col>
              <div>
                <div style={text1}>PR - {prNamn}</div>
                <div style={description}>
                  <img style={mailLogo} src={Mail} alt={"Mail"}></img>
                  pr@mk.chs.chalmers.se
                </div>
                <div style={description}>
                  <img style={phoneLogo} src={Phone} alt={"Telefon"}></img>
                  {prTel}
                </div>
                <div style={description}>
                Bild ej uppdaterad. Som PR designar jag alla affischer som MK trycker upp och det är också jag som har huvudansvaret för hemsidan och att MK syns på sociala medier. Som PR läser och kontrollerar jag mottagningens alla nolluppdrag. Utöver det är jag även kalasansvarig och har dessutom fått äran att leda tåget med alla nya chalmerister från Götaplatsen upp till Chalmers första dagen.
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <div style={divider2}></div>
          </Row>

          {/* Arrangör */}

          <Row lg={2} md={2} sm={1} xs={1} style={{ marginTop: "50px" }}>
            <Col>
              <div>
                <div style={text1}>Arrangör - {arrNamn}</div>
                <div style={description}>
                  <img style={mailLogo} src={Mail} alt={"Mail"}></img>
                  arrangor@mk.chs.chalmers.se
                </div>
                <div style={description}>
                  <img style={phoneLogo} src={Phone} alt={"Telefon"}></img>
                  {arrTel}
                </div>
                <div style={description}>
                Bild ej uppdaterad. I MK är det Arrangören som har hand om samlingen på Götaplatsen första dagen. Jag har även huvudansvar för att planera och genomföra Kappseglingen och Rocken som är deltävlingar i Mottagningskampen - Mottagningens stora tävling. 
                </div>
              </div>
            </Col>
            <Col>
              <img src={Arrangor} style={imgStyleRight} alt={arrNamn}></img>
              {/* <img src={Blank} style={imgStyleRight} alt={arrNamn}></img> */}
            </Col>
          </Row>
          <Row>
            <div style={divider2}></div>
          </Row>

          {/* Organisatör */}

          <Row lg={2} md={2} sm={1} xs={1} style={{ marginTop: "50px" }}>
            <Col>
              <img src={Organisator} style={imgStyle} alt={orgNamn}></img>
              {/* <img src={Blank} style={imgStyle} alt={orgNamn}></img> */}
            </Col>
            <Col>
              <div>
                <div style={text1}>Organisatör - {orgNamn}</div>
                <div style={description}>
                  <img style={mailLogo} src={Mail} alt={"Mail"}></img>
                  organisator@mk.chs.chalmers.se
                </div>
                <div style={description}>
                  <img style={phoneLogo} src={Phone} alt={"Telefon"}></img>
                  {orgTel}
                </div>
                <div style={description}>
                Bild ej uppdaterad. Som Organisatör i MK planerar och organiserar jag samlingen på Teknologgården och presentationerna i RunAn första dagen. Utöver det ansvarar jag dessutom för Tivolit. Det är även jag som planerar och styr upp Finalen och Cheeret, de sista deltävlingarna i den prestigefyllda tävlingen Mottagningskampen. Jag hjälper även Arrangören med de övriga centrala arrangemangen.
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      );
    }
  }
}

const divider2 = {
  backgroundColor: "black",
  width: "100%",
  height: "2px",
  marginTop: "30px",
  position: "relative",
};

const paddingStyle = {
  marginTop: "50px",
};

const imgStyle1 = {
  position: "relative",
  top: "50%",
  transform: "translate(0, -50%)",
  width: "100%",
  borderRadius: "2%",
};
const imgStyle = {
  width: "84%",
  borderRadius: "1%",
  position: "relative",
  left: "8%",
  marginBottom: "10px",
};
const imgStyleRight = {
  float: "right",
  width: "84%",
  borderRadius: "1%",
  position: "relative",
  right: "8%",
};
const lowOp = {
  position: "absolute",
  width: "70%",
  borderRadius: "1%",
  opacity: "0.1",
  marginLeft: "10%",
};

const description = {
  top: "0%",
  left: "0%",
  fontSize: "1.2rem",
  marginBottom: "5px",
  position: "relative",
  textAlign: "justify",
};
const mailLogo = {
  width: "3%",
  marginRight: "10px",
};

const phoneLogo = {
  width: "3%",
  marginRight: "10px",
  position: "relative",
};

const topMargin = {
  marginTop: "50px",
};

export default About;
